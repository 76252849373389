import React from "react";

import Layout from "../components/organisms/layout";
import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";

import Nav from "../components/molecules/nav";
import SEO from "../components/molecules/seo";

const Bedankt = () => (
  <Layout>
    <SEO
      title="Contacteer ons"
      description="Had u toch nog vragen of wil u een offerte ontvangen? Contacteer ons nu vrijblijvend voor een voorstel."
    />

    <Nav />

    <Header heading="Neem contact met ons op" />

    <section
      className="pattern-bg pt-8 pb-5"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h3>Uw aanvraag is succesvol verzonden!</h3>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default Bedankt;
